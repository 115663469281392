// import LearnMoreCard from "./LearnMoreCard";
import TradeChart from "../../../assets/svg/tradechart-1.svg";
import Coins from "../../../assets/svg/coins-1.svg";
import "./LearnMore.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import i18next from "i18next";

const LearnMore = () => {
  const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');

  useEffect(() => {
    if (i18next.language === 'ar'){
      setRedirectLang('?lang=ar');
    } else{
      setRedirectLang('?lang=en');
    }

    if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
      setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
    } else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
        setRegistrationLink(`https://secure.supremefxtrading.net/register`);
      }else{
      setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
    }
  }, [i18next.language, redirectLang]);

  return (
    <div className="section learn-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="w-50">
              <h2>{t('Learn more about SupremeFX')}</h2>
              <p className="p1-24">
              {t('By offering a unique and personal trading experience, Supreme FX is the ideal choice for our both experienced and new traders alike.')}
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-12" style={{ gridRow: "span 2" }}>
            <div className="card">
              <div className="card-body">
                <Link
                  to={registrationLink}
                  className="theme-light-btn text-white"
                  style={{ color: "#fff", marginBottom: "20px" }}
                >
                  {t('Open Account')}
                </Link>
                <h5>{t('A Powerful Platform For Forex And Exchange Markets')}</h5>
                <img loading="lazy" src={TradeChart} alt="" />
                <h6>{t('Instruments from different assets')}</h6>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-6">
            <div
              className="card text-center"
              style={{ backgroundColor: "#CDE3FA" }}
            >
              <div className="card-body">
                <Link to="/account-type/" className="theme-light-btn text-white">
                  {t('Learn more')}
                </Link>
                <div className="d-flex justify-content-center">
                  <h5 style={{ width: "149px" }}>{t('Account types')}</h5>
                </div>
                <p className="p2">{t('Trillion Daily Turnover in the FX Market')}</p>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-6">
            <div className="card text-center">
              <div className="card-body">
                <Link to="/contact/" className="theme-light-btn text-white">
                  {t('Contact us')}
                </Link>
                <div className="d-flex justify-content-center">
                  <h5 style={{ width: "168px" }}>{t('Ask us anything')}</h5>
                </div>
                <p className="p2">{t('24/7 customer support & AI integrated services')}</p>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-12 mt-4"
            style={{ gridColumn: "span 2" }}
          >
            <div className="card last-card">
              <div className="card-body">
                <span className="disabled-botton">
                  {t('Coming Soon')}
                </span>
                <div
                  className=""
                  style={{ marginBottom: "50px", marginTop: "24px" }}
                >
                  <h5>
                    {t('Stay up to date with latest market news')}
                  </h5>
                </div>
                <img loading="lazy"
                  src={Coins} alt=""
                  style={{
                    position: "absolute",
                    bottom: "0",
                    insetInlineEnd: "0",
                    width: "40%",
                  }}
                />
                <p className="p2" style={{ width: "272px" }}>
                  {t('Make informed investment decisions with confidence.')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
