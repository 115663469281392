import { useRef, useEffect, useState } from "react";
import Mobile from "../../../assets/features-mobile.png";
import TickCircle from "../../../assets//svg/tick-circle.svg";
import "../../../index.css";
import "./Features.css";
import Button from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Features = () => {
  const {t} = useTranslation();
  const featureRef = useRef(null);
  let container, featureImg;
  const [registrationLink, setRegistrationLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');
  

  function handleImage(){
    featureImg.classList.remove('show');
    setTimeout( () => featureImg.classList.add('show'), 1000);
  }

    useEffect(() => {
      if (i18next.language === 'ar'){
        setRedirectLang('?lang=ar');
      } else{
        setRedirectLang('?lang=en');
      }

      if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
        setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
      } else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
        setRegistrationLink(`https://secure.supremefxtrading.net/register`);
      }else{
        setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
      }

        const featureObserver = new IntersectionObserver(entry =>{
            if (entry[0].isIntersecting){
                container = document.querySelector(".features-section");
                featureImg = container.querySelector('.footer-logo img');
                featureImg.classList.add('show');
            }
        }, 
        {
          threshold: 0.5,
        }
        )
        featureObserver.observe(featureRef.current);
        
        return () => {
          featureObserver.disconnect();
        };
      }, [i18next.language, redirectLang]);

  return (
    <div className="section features-section" ref={featureRef} style={{"backgroundColor": "#fff"}}>
      <div
        className="container"
        style={{
          backgroundColor: "#CDE3FA",
          padding: "45px 80px 0",
          borderRadius: "32px",
        }}
      >
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-7 col-sm-12 order-lg-1 order-2">
            {/* <div className="footer-logo" onMouseEnter={()=>{(featureAnimationCount >= 1)?setFeatureAnimationCount(0):setFeatureAnimationCount(featureAnimationCount + 1)}}> */}
            {/* <div className="footer-logo" onMouseEnter={()=>{handleImage()}}> */}
            <div className="footer-logo">
              <img loading="lazy" className="w-75" src={Mobile} />
            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 order-lg-2 order-1">
            <h4>{t('SupremeFX Features')}</h4>
            <div className="features-list">
              <ul className="p-0 m-0">
                <li>
                  <img src={TickCircle} alt="" />
                  {t('Directly linked to your SupremeFX trading account.')}
                </li>
                <li>
                  <img src={TickCircle} alt="" />
                  {t('Secure Online and Offline transactions.')}
                </li>
                <li>
                  <img src={TickCircle} alt="" />
                  {t('Withdraw your trading profits via ATMs globally.')}
                </li>
                <li>
                  <img src={TickCircle} alt="" />
                  {t('Online client support.')}
                </li>
                <li>
                  <img src={TickCircle} alt="" />
                  {t('A dedicated mobile app to manage your deposits and withdrawals with ease.')}
                </li>
              </ul>
            </div>

            <div className="feature-btn">
              <Link to={registrationLink}>
                <Button className="theme-red-btn" btnTxt={t('Get Started')} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
