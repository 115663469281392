import React from 'react'
import { useTranslation } from 'react-i18next';

const FooterSv = () => {
    const {t} = useTranslation();

  return (
    <>
    <p>{t('High Risk Investment Warning: Trading Foreign Exchange on margin carries a high level of risk and may not be suitable for all investors. The high degree of leverage can work against you as well as for you. Before deciding to trade foreign exchange, you should carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all your initial investment and therefore you should not invest money that you cannot afford to lose. You should be aware of all the risks associated with foreign exchange trading and seek advice from an independent financial adviser if you have any doubts.')}</p>

    <p>{t('Risk Warning: CFDs (Contracts For Difference) are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Please read the full Risk Disclosure.')}</p>

    <p>{t('Regional Restrictions: “Supreme FX LLC” does not provide investment and ancillary services in the territories of the United States of America, Canada, Israel, Japan, North Korea, Belgium, and UN/EU Sanctioned countries.')} </p>

    <p>{t('Supreme FX Group of Companies, include:')} </p>
    <div className='row'>
        <div className='col-md-5'>
          <ul>
            <li>{t('Supreme FX LLC (Saint Vincent & the Grenadines)')}</li>
            <li>{t('Sun Capital Markets ltd trade name “Supreme FX” (Republic of Seychelles)')}</li>
          </ul>
        </div>
        <div className='col-md-7'>
          <ul>
            <li>{t('Sun Capital Markets ltd trade name “Supreme FX Representative Office (United Arab Emirates)')}</li>
            <li>{t('Supremex Commercial Brokers (United Arab Emirates)')}</li>
          </ul>
        </div>
      </div>

    <p>{t('“Supreme FX LLC” is incorporated in Saint Vincent & the Grenadines as an International Business Company with registration number No. 1497 LLC 2021. Registered address at Euro House, Richmond Hill Road, P.O. Box 2897 Kingstown, St. Vincent and the Grenadines. The website www.supremefxtrading.sv is operated by Supreme FX LLC.')}</p>

    <p>{t('“Supreme FX” tradename for Sun Capital Markets ltd. is an Investment Firm incorporated under the laws of the Republic of Seychelles with company registration number 8431497-1. Authorized and regulated by the Financial Services Authority (FSA) in Seychelles (License No SD145). Registered address at CT House, Office E4, Providence Mahe’, Republic of Seychelles. The website www.supremefxtrading.com is operated by Sun Capital Markets ltd “Supreme FX”.')}</p>
    
    <p>{t('Representative Office of Sun Capital Markets ltd “Supreme FX” in the United Arab Emirates is registered and licensed by the Dubai Economic Department (DED) in Dubai (License No. 1273677). Registered address at Office No. 603, the Maze Tower, Shaikh Zayed Road, DIFC, Dubai, United Arab Emirates')}</p>
    </>
  )
}

export default FooterSv